import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/:dealerID",
    name: "IndexID",
    component: () => import("../views/Index.vue"),
  },
  {
    path: "/",
    name: "Index",
    component: () => import("../views/Index.vue"),
  },
  // {
  //   path: "/:dealerID/time",
  //   name: "Time",
  //   component: () => import("../views/Time.vue"),
  // },
  // {
  //   path: "/:dealerID/contact/:time",
  //   name: "Contact",
  //   component: () => import("../views/Contact.vue"),
  // },
  // {
  //   path: "/:dealerID/success/:time",
  //   name: "Success",
  //   component: () => import("../views/Success.vue"),
  // },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(_to, _from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;

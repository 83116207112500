import Vue from 'vue';
import App from './App.vue';
import router from './router';
import axios from 'axios';
import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();

import Directives from './directives';
Directives(Vue);

Vue.config.productionTip = false;
Vue.prototype.$http = axios;
window.dealers = [
  {
    id: 'db957b07376ce841',
    name: 'Hedin Bil Segeltorp',
    addressHeader: 'Smista allé 36',
    addressBody: 'Segeltorp',
    fullDate: '2024-03-14',
    date: '14.03',
    city: 'Segeltorp',
    disabledTime: ['10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '18:00', '18:30', '19:00', '19:30'],
  },
  {
    id: 'b274f76025b644d8',
    name: 'Hedin Bil Akalla',
    addressHeader: 'Esbogatan 8',
    addressBody: 'Kista',
    fullDate: '2024-03-15',
    date: '15.03',
    city: 'Kista',
    disabledTime: ['10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '18:00', '18:30', '19:00', '19:30'],
  },
  {
    id: '512e192e8bf31ce9',
    name: 'Hedin Bil Uppsala',
    addressHeader: 'Kumlagatan 12',
    addressBody: 'Uppsala',
    fullDate: '2024-03-18',
    date: '18.03',
    city: 'Uppsala',
    disabledTime: ['10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '18:00', '18:30', '19:00', '19:30'],
  },
  {
    id: '4a729fcb0e3b3593',
    name: 'Hedin Bil Eskilstuna',
    addressHeader: 'Nystrandsgatan 1',
    addressBody: 'Eskilstuna',
    fullDate: '2024-03-19',
    date: '19.03',
    city: 'Eskilstuna',
    disabledTime: ['10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '18:00', '18:30', '19:00', '19:30'],
  },
  {
    id: 'f035eaf3b1f108df',
    name: 'Hedin Bil Malmö',
    addressHeader: 'Jägersrovägen 100',
    addressBody: 'Malmö',
    fullDate: '2024-03-21',
    date: '21.03',
    city: 'Malmö',
    disabledTime: ['10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '18:00', '18:30', '19:00', '19:30'],
  },
  {
    id: 'ea111b5c53a8ed98',
    name: 'Hedin Bil Kristianstad',
    addressHeader: 'Sävvägen 1',
    addressBody: 'Kristianstad',
    fullDate: '2024-03-22',
    date: '22.03',
    city: 'Kristianstad',
    disabledTime: ['10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '18:00', '18:30', '19:00', '19:30'],
  },
  {
    id: 'b721d86a85d38961',
    name: 'Bilmånsson Eslöv',
    addressHeader: 'Vikhemsvägen 2',
    addressBody: 'Eslöv',
    fullDate: '2024-03-23',
    date: '23.03',
    city: 'Eslöv',
    disabledTime: [
      '10:00',
      '10:30',
      '15:00',
      '15:30',
      '16:00',
      '16:30',
      '17:00',
      '17:30',
      '18:00',
      '18:30',
      '19:00',
      '19:30',
    ],
  },
  {
    id: '585e41b5694ea30b',
    name: 'Göinge Bil Hässleholm',
    addressHeader: 'Norra Kringelv. 70',
    addressBody: 'Hässleholm',
    fullDate: '2024-03-25',
    date: '25.03',
    city: 'Hässleholm',
    disabledTime: ['10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '18:00', '18:30', '19:00', '19:30'],
  },
  {
    id: 'debd0a874dca3189',
    name: 'Hedin Bil Helsingborg',
    addressHeader: 'Garnisonsgatan 5',
    addressBody: 'Helsingborg',
    fullDate: '2024-03-26',
    date: '26.03',
    city: 'Helsingborg',
    disabledTime: ['10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '18:00', '18:30', '19:00', '19:30'],
  },
  {
    id: 'e5c99a01b8d382ac',
    name: 'Hedin Bil Halmstad',
    addressHeader: 'Olofsdalsvägen 33',
    addressBody: 'Halmstad',
    fullDate: '2024-03-27',
    date: '27.03',
    city: 'Halmstad',
    disabledTime: ['10:00', '10:30', '11:00', '11:30', '12:00', '12:30'],
  },
  {
    id: '2b5c39111835897b',
    name: 'Liljas bil Växjö',
    addressHeader: 'Ljungadalsgatan 1',
    addressBody: 'Växjö',
    fullDate: '2024-04-02',
    date: '02.04',
    city: 'Växjö',
    disabledTime: ['10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '18:00', '18:30', '19:00', '19:30'],
  },
  {
    id: 'db7380b8fedb2cc8',
    name: 'Liljas bil Kalmar',
    addressHeader: 'Trångsundsvägen 20',
    addressBody: 'Kalmar',
    fullDate: '2024-04-03',
    date: '03.04',
    city: 'Kalmar',
    disabledTime: ['10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '18:00', '18:30', '19:00', '19:30'],
  },
  {
    id: '02408c22bb972c09',
    name: 'Hedin Bil Jönköping',
    addressHeader: 'Bataljonsgatan 14',
    addressBody: 'Jönköping',
    fullDate: '2024-04-04',
    date: '04.04',
    city: 'Jönköping',
    disabledTime: ['10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '18:00', '18:30', '19:00', '19:30'],
  },
  {
    id: '548a2b7d8dc6954c',
    name: 'Hedin bil Borås',
    addressHeader: 'Pickesjövägen 2',
    addressBody: 'Borås',
    fullDate: '2024-04-05',
    date: '05.04',
    city: 'Borås',
    disabledTime: ['10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '18:00', '18:30', '19:00', '19:30'],
  },
  {
    id: 'fb4a9349746956e7',
    name: 'Hedin Bil Åbro/Mölndal',
    addressHeader: 'Argongatan 20',
    addressBody: 'Mölndal',
    fullDate: '2024-04-06',
    date: '06.04',
    city: 'Mölndal',
    disabledTime: [
      '10:00',
      '10:30',
      '15:00',
      '15:30',
      '16:00',
      '16:30',
      '17:00',
      '17:30',
      '18:00',
      '18:30',
      '19:00',
      '19:30',
    ],
  },
  {
    id: 'e99819acda447054',
    name: 'Hedin Bil Åbro/Mölndal',
    addressHeader: 'Argongatan 20',
    addressBody: 'Mölndal',
    fullDate: '2024-04-07',
    date: '07.04',
    city: 'Mölndal',
    disabledTime: [
      '10:00',
      '10:30',
      '15:00',
      '15:30',
      '16:00',
      '16:30',
      '17:00',
      '17:30',
      '18:00',
      '18:30',
      '19:00',
      '19:30',
    ],
  },
  {
    id: 'be472b796334eba1',
    name: 'Hedin Bil Åbro',
    addressHeader: 'Argongatan 20',
    addressBody: 'Mölndal',
    fullDate: '2024-04-08',
    date: '08.04',
    city: 'Mölndal',
    disabledTime: ['10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '18:00', '18:30', '19:00', '19:30'],
  },
  {
    id: '3c7d57f7918a75f4',
    name: 'Stendahls bil Stenungsund',
    addressHeader: 'Munkerödsvägen 3',
    addressBody: 'Stenungsund',
    fullDate: '2024-04-09',
    date: '09.04',
    city: 'Stenungsund',
    disabledTime: ['10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '18:00', '18:30', '19:00', '19:30'],
  },
  {
    id: '6d38e7f60ec5b793',
    name: 'Brandt Bil Uddevalla',
    addressHeader: 'Kärranäsvägen 2',
    addressBody: 'Uddevalla',
    fullDate: '2024-04-10',
    date: '10.04',
    city: 'Uddevalla',
    disabledTime: ['10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '18:00', '18:30', '19:00', '19:30'],
  },
  {
    id: 'c1019353c6814476',
    name: 'Brandt Bil Skövde',
    addressHeader: 'Mellomkvarnsvägen 2',
    addressBody: '549 60 Skövde',
    fullDate: '2024-04-11',
    date: '11.04',
    city: 'Skövde',
    disabledTime: ['10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '18:00', '18:30', '19:00', '19:30'],
  },
  {
    id: '0b4b9bdf4e7827e3',
    name: 'Helmia Bil Karlstad',
    addressHeader: 'Packhusgatan 1',
    addressBody: 'Karlstad',
    fullDate: '2024-04-12',
    date: '12.04',
    city: 'Karlstad',
    disabledTime: ['10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '18:00', '18:30', '19:00', '19:30'],
  },
  {
    id: 'b400ceab9872ea12',
    name: 'Skobes bil Nyköping',
    addressHeader: 'Gumsbackevägen 4',
    addressBody: 'Nyköping',
    fullDate: '2024-04-19',
    date: '19.04',
    city: 'Nyköping',
    disabledTime: ['10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '18:00', '18:30', '19:00', '19:30'],
  },
  {
    id: 'c64b51ef6e768a2d',
    name: 'Tage Rejmes Norrköping',
    addressHeader: 'Lindövägen 60',
    addressBody: 'Norrköping',
    fullDate: '2024-04-22',
    date: '22.04',
    city: 'Norrköping',
    disabledTime: ['10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '18:00', '18:30', '19:00', '19:30'],
  },
  {
    id: 'lux2whyrsoibe920',
    name: 'Skobes Bil Motala',
    addressHeader: 'Vintergatan 9',
    addressBody: 'Motala',
    fullDate: '2024-04-23',
    date: '23.04',
    city: 'Motala',
    disabledTime: ['10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '18:00', '18:30', '19:00', '19:30'],
  },
  {
    id: 'a3vaebar67y654ag',
    name: 'Tage Rejmes Örebro',
    addressHeader: 'Bettorpsgatan 4-6',
    addressBody: 'Örebro',
    fullDate: '2024-04-24',
    date: '24.04',
    city: 'Örebro',
    disabledTime: ['10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '18:00', '18:30', '19:00', '19:30'],
  },
  {
    id: '1yf2us6wknmrt339',
    name: 'Rolf Ericson Bil Borlänge',
    addressHeader: 'Smedjebacksvägen 3',
    addressBody: 'Borlänge',
    fullDate: '2024-04-25',
    date: '25.04',
    city: 'Borlänge',
    disabledTime: ['10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '18:00', '18:30', '19:00', '19:30'],
  },
  {
    id: 'meh9ceu0zf0t9j8a',
    name: 'Bilbolaget Gävle',
    addressHeader: 'Södra Kungsgatan 61-63',
    addressBody: 'Gävle',
    fullDate: '2024-04-26',
    date: '26.04',
    city: 'Gävle',
    disabledTime: ['10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '18:00', '18:30', '19:00', '19:30'],
  },
  {
    id: 'wei47x11t1kvbgi6',
    name: 'Bilbolaget Östersund',
    addressHeader: 'Fagerbacken 53',
    addressBody: 'Östersund',
    fullDate: '2024-05-06',
    date: '06.05',
    city: 'Östersund',
    disabledTime: ['10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '18:00', '18:30', '19:00', '19:30'],
  },
  {
    id: 'gtwi9oifm0biqtr8',
    name: 'Bilbolaget Timrå',
    addressHeader: 'Terminalvägen 2',
    addressBody: 'Timrå',
    fullDate: '2024-04-30',
    date: '30.04',
    city: 'Timrå',
    disabledTime: ['10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '18:00', '18:30', '19:00', '19:30'],
  },

  {
    id: 'yptjnbezj30o70ld',
    name: 'Bilbolaget Luleå',
    addressHeader: 'Gammelstadsvägen 23',
    addressBody: 'Luleå',
    fullDate: '2024-05-08',
    date: '08.05',
    city: 'Luleå',
    disabledTime: ['10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '18:00', '18:30', '19:00', '19:30'],
  },
];

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');
